import Vue from "vue";
import App from "./App.vue";
import "./utils/vant";
import axios from "axios";

const api = axios.create({
  // baseURL: "/api",
  baseURL: "https://marksix.com.tw/sys-upload/data/lotery",
  timeout: 3000,
});

Vue.prototype.$axios = api;
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
