import Vue from "vue";
import {
  Button,
  Icon,
  Tab,
  Tabs,
  Grid,
  GridItem,
  Cell,
  CountDown,
  Notify,
  Divider,
  Loading,
} from "vant";

Vue.use(Button);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(CountDown);
Vue.use(Notify);
Vue.use(Divider);
Vue.use(Loading);
