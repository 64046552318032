<template>
  <div id="kjapp">
    <OpenNum />
  </div>
</template>

<script>
import OpenNum from "./views/Index.vue";

export default {
  components: {
    OpenNum,
  },
};
</script>
<style>
.van-notify {
  padding: 10px 16px !important;
}
</style>

