<template>
  <div class="kai-jiang">
    <van-tabs type="card" animated v-model="active" swipeable scrollspy>
      <van-tab :title="item.name" v-for="(item, index) in lotteries" :key="index">
        <div class="tabs-content">
          <div class="kj-top">
            <span>
              第<span class="kj-period"> {{ item.pre.turnNum || "- -" }} </span>开奖结果
            </span>
            <span>
              <a class="kj-history" :href="`../../history/index.html?${active ? 'lottery_id=1' : 'lottery_id=5'}`" target="_blank"> 历史开奖结果 </a>
              <van-icon name="arrow" />
            </span>
          </div>

          <div class="kj-num-list" style="height: 76px" v-if="!loading">
            <div class="kj-num-item" v-for="(child, index) in item.openNumArr" :key="index">
              <div class="kj-num-img">
                <img :src="require(`../assets/image/ball-${child.color}.png`)" />
                <span class="kj-num-text">{{ child.num }}</span>
              </div>
              <span class="kj-num-bottom-text">{{ child.animals }}</span>
            </div>
          </div>
          <div class="kj-num-list" style="height: 76px" v-if="loading">
            <van-loading type="spinner" size="22px">数据同步中...</van-loading>
          </div>
          <van-cell value="内容" center>
            <template #title>
              <span>
                第
                <span class="kj-period"> {{ item.cur.turn || "- -" }} </span>期开奖倒计时
              </span>
            </template>
            <template #label>
              <span
                >截止时间 : <span> {{ item.closeTime }} </span></span
              >
            </template>
            <template #default>
              <van-count-down :time="item.countDownTime" @finish="finishTime" :auto-start="countDownStart">
                <template #default="timeData">
                  <span class="block">{{ timeData.days * 24 + timeData.hours < 10 ? "0" + (timeData.days * 24 + timeData.hours) : timeData.days * 24 + timeData.hours }}</span>
                  <span class="colon">:</span>
                  <span class="block">{{ timeData.minutes < 10 ? "0" + timeData.minutes : timeData.minutes }}</span>
                  <span class="colon">:</span>
                  <span class="block">{{ timeData.seconds < 10 ? "0" + timeData.seconds : timeData.seconds }}</span>
                </template>
              </van-count-down>
            </template>
          </van-cell>
        </div>
      </van-tab>
    </van-tabs>
    <div class="refresh" @click="handleRefresh">
      <img :class="loading ? 'refresh-loading' : ''" src="../assets/rotate.svg" alt="refresh" />
      <span>刷新</span>
    </div>
  </div>
</template>

<script>
import { throttle } from "lodash";
import { GET_ANIMALS_NATURE, GET_ANIMAL_NUM_COLOR, ANIMALS_NUM_COLOR } from "../utils/matchnum";
export default {
  data() {
    return {
      active: 0,
      loading: false,
      timeData: "HH:mm:ss",
      animalsNumColor: ANIMALS_NUM_COLOR,
      lotteries: [
        {
          id: 0,
          name: "新澳六合彩",
          requrl: "/90.json",
          cur: {},
          pre: {},
          closeTime: "",
          countDownTime: 0,
          openNumArr: [],
        },
        {
          id: 1,
          name: "香港六合彩",
          requrl: "/70.json",
          cur: {},
          pre: {},
          closeTime: "",
          countDownTime: 0,
          openNumArr: [],
        },
      ],
      defaultValue: [
        "新,澳,正,在,摇,号,中",
        // "新,港,正,在,摇,号,中",
        "港,彩,正,在,摇,号,中",
      ],
      requrl: "", // 请求地址
      isStreaming: "", // 开奖状态
      countDownStart: false, // 计时状态
      finishTimer: null,
    };
  },
  created() {
    this.requrl = this.lotteries[this.active].requrl;
    this.getReqData(this.requrl, this.active);
    clearInterval(this.finishTimer);
  },
  watch: {
    active(newVal) {
      this.active = newVal;
      this.requrl = this.lotteries[newVal].requrl;
      this.getReqData(this.requrl, newVal);
    },
    isStreaming(newVal) {
      clearInterval(this.finishTimer);
      if (newVal === 1) {
        this.finishTimer = setInterval(() => {
          this.getReqData(this.requrl, this.active);
        }, 2000);
      }
    },
  },
  methods: {
    // 计时结束
    finishTime() {
      clearInterval(this.finishTimer);
      this.finishTimer = setInterval(() => {
        this.getReqData(this.requrl, this.active);
      }, 2000);
    },
    // 刷新
    handleRefresh: throttle(function () {
      this.loading = true;
      this.lotteries[this.active].openNumArr = [];
      this.getReqData(this.requrl, this.active);
    }, 2000),
    // 初始化数据
    async getReqData(requrl, tabIndex) {
      const NOW_TIME = new Date().getTime();
      try {
        const RESPONSE_DATA = await this.$axios.get(`${requrl}?_t=${NOW_TIME}`);
        const OPEN_NUM_ARR = GET_ANIMALS_NATURE(this.defaultValue, RESPONSE_DATA.data.pre.openNum, tabIndex);
        const DOM_DATA = GET_ANIMAL_NUM_COLOR(this.animalsNumColor, OPEN_NUM_ARR);
        this.lotteries[tabIndex].openNumArr = DOM_DATA;
        this.lotteries[tabIndex].cur = RESPONSE_DATA.data.cur;
        this.lotteries[tabIndex].pre = RESPONSE_DATA.data.pre;
        this.lotteries[tabIndex].closeTime = RESPONSE_DATA.data.cur.closeTime ? this.setTime(RESPONSE_DATA.data.cur.closeTime) : "- -";
        if (RESPONSE_DATA.data.cur.closeTime - NOW_TIME > 0) {
          this.lotteries[tabIndex].countDownTime = RESPONSE_DATA.data.cur.closeTime - NOW_TIME;
        } else {
          this.lotteries[tabIndex].countDownTime = 0;
        }
        this.countDownStart = true;
        this.isStreaming = RESPONSE_DATA.data.pre.isStreaming;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          message: "数据请求失败，请重试......",
          duration: 1000,
        });
      }
    },
    // 时间转换
    setTime(time) {
      const timestamp = time;
      const date = new Date(timestamp);
      let Y = date.getFullYear();
      let Mo = date.getMonth() + 1 < 10 ? "0" + date.getMonth() + 1 : date.getMonth() + 1 + "";
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let H = date.getHours();
      let Mi = date.getMinutes();
      let S = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let closeTime = Y + "-" + Mo + "-" + D + " " + H + ":" + Mi + ":" + S;
      return closeTime;
    },
  },
  beforeDestroy() {
    clearInterval(this.finishTimer);
  },
};
</script>

<style scoped lang="scss">
.kai-jiang {
  padding: 10px;
  position: relative;
  // height: 220px;
  box-sizing: border-box;
}
.refresh {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  user-select: none;
  // padding: 5px;
  img {
    width: 14px;
  }
  span {
    font-size: 13px;
  }
}

/* 在屏幕宽度小于600像素时应用的样式 */

@media (max-width: 800px) {
  ::v-deep .van-tabs__wrap {
    width: 40%;
  }
}
@media (max-width: 640px) {
  ::v-deep .van-tabs__wrap {
    width: 75%;
  }
}
@media (max-width: 320px) {
  ::v-deep .van-tabs__wrap {
    width: 80%;
  }
}
@media (min-width: 800px) {
  ::v-deep .van-tabs__wrap {
    width: 35%;
  }
}
::v-deep .van-tabs__wrap {
  // margin: 10px 0 10px 10px;
  margin-bottom: 10px;
}
::v-deep .van-tab {
  border-radius: 15px;
  border: 1px solid #3e5ea1;
  margin-right: 5px;
  background-color: #3e5ea1;
}
::v-deep .van-tabs__nav--card .van-tab {
  color: #fff;
}
::v-deep .van-tabs__nav--card .van-tab.van-tab--active {
  color: #3e5ea1;
}
::v-deep .van-tabs__nav.van-tabs__nav--card {
  margin: 0;
  border: none;
}
::v-deep .van-tabs__nav--card .van-tab:last-child {
  border-right: 1px solid #3e5ea1;
}
::v-deep .van-tabs__nav--card .van-tab {
  border-right: 1px solid #3e5ea1;
}
::v-deep .van-tabs__nav--card .van-tab.van-tab--active {
  background-color: #ffffff;
}

// tab内容
.tabs-content {
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
}

.kj-num-list {
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}
.kj-num-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.kj-num-img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  position: relative;
}

.kj-num-text {
  position: absolute;
  top: 45%;
  left: 49%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  font-size: 16px;
}
.kj-num-bottom-text {
  font-size: 16px;
  color: #606469;
  text-align: center;
}
.kj-num-item img {
  width: 100%;
  height: 100%;
}

// 最新开奖信息
.kj-top {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.kj-period {
  color: #ff0000;
}
.kj-history {
  color: #748ebb;
  cursor: pointer;
  &:hover {
    color: #2062d5;
  }
}

.colon {
  // display: inline-block;
  // margin: 0 4px;
  color: #0b0b0b;
  // background-color: #ffffff;
  padding: 5px 4px;
}
.block {
  // display: inline-block;
  // width: 28px;
  // height: 28px;
  line-height: 28px;
  padding: 6px;
  color: #0b0b0b;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 6px;
  // border: 1px solid #f3f3f3;
  box-sizing: border-box;
  text-align: center;
}

::v-deep .van-cell {
  background: #f2f4fa;
  padding: 5px;
  border-radius: 8px;
}

// 刷新动画
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.refresh-loading {
  animation: spin 2s linear infinite;
}

::v-deep .van-cell__label span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.van-count-down {
  white-space: nowrap;
}
</style>
