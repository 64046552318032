export const ANIMALS_NUM_COLOR = [
  { animals: "兔", num: "01", color: "red" },
  { animals: "虎", num: "02", color: "red" },
  { animals: "牛", num: "03", color: "blue" },
  { animals: "鼠", num: "04", color: "blue" },
  { animals: "猪", num: "05", color: "green" },
  { animals: "狗", num: "06", color: "green" },
  { animals: "鸡", num: "07", color: "red" },
  { animals: "猴", num: "08", color: "red" },
  { animals: "羊", num: "09", color: "blue" },
  { animals: "马", num: "10", color: "blue" },
  { animals: "蛇", num: "11", color: "green" },
  { animals: "龙", num: "12", color: "red" },
  { animals: "兔", num: "13", color: "red" },
  { animals: "虎", num: "14", color: "blue" },
  { animals: "牛", num: "15", color: "blue" },
  { animals: "鼠", num: "16", color: "green" },
  { animals: "猪", num: "17", color: "green" },
  { animals: "狗", num: "18", color: "red" },
  { animals: "鸡", num: "19", color: "red" },
  { animals: "猴", num: "20", color: "blue" },
  { animals: "羊", num: "21", color: "green" },
  { animals: "马", num: "22", color: "green" },
  { animals: "蛇", num: "23", color: "red" },
  { animals: "龙", num: "24", color: "red" },
  { animals: "兔", num: "25", color: "blue" },
  { animals: "虎", num: "26", color: "blue" },
  { animals: "牛", num: "27", color: "green" },
  { animals: "鼠", num: "28", color: "green" },
  { animals: "猪", num: "29", color: "red" },
  { animals: "狗", num: "30", color: "red" },
  { animals: "鸡", num: "31", color: "blue" },
  { animals: "猴", num: "32", color: "green" },
  { animals: "羊", num: "33", color: "green" },
  { animals: "马", num: "34", color: "red" },
  { animals: "蛇", num: "35", color: "red" },
  { animals: "龙", num: "36", color: "blue" },
  { animals: "兔", num: "37", color: "blue" },
  { animals: "虎", num: "38", color: "green" },
  { animals: "牛", num: "39", color: "green" },
  { animals: "鼠", num: "40", color: "red" },
  { animals: "猪", num: "41", color: "blue" },
  { animals: "狗", num: "42", color: "blue" },
  { animals: "鸡", num: "43", color: "green" },
  { animals: "猴", num: "44", color: "green" },
  { animals: "羊", num: "45", color: "red" },
  { animals: "马", num: "46", color: "red" },
  { animals: "蛇", num: "47", color: "blue" },
  { animals: "龙", num: "48", color: "blue" },
  { animals: "兔", num: "49", color: "green" },
];
/**
 *
 * @param {*} defaultValue tabs默认数据
 * @param {*} openNum 开奖号码
 * @param {*} activeTab 激活中的tab下标
 * @returns 返回开奖号码数组（如果号码为空则为默认数据）
 */
export const GET_ANIMALS_NATURE = (defaultValue, openNum, activeTab) => {
  // 字符串转换成数组
  const DEFAULT_VALUE_ARR = defaultValue[activeTab].split(",");
  const OPEN_NUM_ARR = openNum.split(",");
  // 将默认值替换为开奖号码
  const NEW_ARRAY = DEFAULT_VALUE_ARR.map((value, index) => {
    if (OPEN_NUM_ARR.length > index && OPEN_NUM_ARR[index] !== "") {
      return OPEN_NUM_ARR[index];
    } else {
      return value;
    }
  });
  return NEW_ARRAY;
};
/**
 *
 * @param {*} animalsNumColor 号码、属性和颜色 数组对象
 * @param {*} newArray 异步请求的数据
 * @returns 返回符合dom渲染格式的数组对象
 */
export const GET_ANIMAL_NUM_COLOR = (animalsNumColor, newArray) => {
  const NEW_ARR = [];
  for (let item of newArray) {
    const ITEM = animalsNumColor.find((child) => child.num === item);
    if (ITEM) {
      NEW_ARR.push(ITEM);
    } else {
      NEW_ARR.push({ animals: "", num: item, color: "white" });
    }
  }
  // 在数组倒数第一位插入符号“ + ”
  NEW_ARR.splice(-1, 0, { animals: "", num: "+", color: "white" });
  return NEW_ARR;
};
